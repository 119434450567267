import { cn } from "@/lib/frontend/shadcn"
import { IconX, IconArrowLeft, IconArrowRight } from "@tabler/icons-react"
import { InfoWindow, InfoWindowProps } from "@vis.gl/react-google-maps"
import { Button } from "@/components/ui/button"
import { Flex } from "@/components/ui/flex"

export type MapMarkerModalProps = InfoWindowProps & {
  children: React.ReactNode
  childrenClassName?: string
  showCustomPointer?: boolean
  showCloseButton?: boolean
  showNextPreviousButtons?: boolean
  disableNextButton?: boolean
  disablePreviousButton?: boolean
  onPreviousClick?: () => void
  onNextClick?: () => void
}

export const MapMarkerModal: React.FC<MapMarkerModalProps> = ({
  showCustomPointer = false,
  children,
  childrenClassName,
  showCloseButton = true,
  showNextPreviousButtons,
  disablePreviousButton,
  disableNextButton,
  onPreviousClick,
  onNextClick,
  className,
  onClose,
  ...props
}) => {
  return (
    <InfoWindow {...props} className={cn("gap-2 p-6 font-sans grid box-content", className)}>
      <Flex justify={showNextPreviousButtons ? "between" : "end"} align="center">
        {showNextPreviousButtons && (
          <Flex className={cn("gap-1.5")}>
            <Button
              variant="transparent"
              className={cn("h-10 w-10 p-5 drop-shadow-lg bg-white")}
              onClick={onPreviousClick}
              disabled={disablePreviousButton}
            >
              <IconArrowLeft size={20} className={cn("flex-shrink-0")} />
            </Button>

            <Button
              variant="transparent"
              className={cn("h-10 w-10 p-5 drop-shadow-lg bg-white")}
              onClick={onNextClick}
              disabled={disableNextButton}
            >
              <IconArrowRight size={20} className={cn("flex-shrink-0")} />
            </Button>
          </Flex>
        )}
        {showCloseButton && (
          <Button variant="transparent" className={cn("h-10 w-10 p-5 drop-shadow-lg bg-white")} onClick={onClose}>
            <IconX size={20} className={cn("flex-shrink-0")} />
          </Button>
        )}
      </Flex>
      <Flex direction="column" className={cn("p-3 gap-3 drop-shadow-lg bg-white rounded-[12px]", childrenClassName)}>
        {showCustomPointer && (
          // custom triangle pointer for modal
          <svg
            width="48"
            height="12"
            viewBox="0 0 48 12"
            className="absolute left-1/2 bottom-0 -translate-x-1/2 translate-y-[60%]"
          >
            <path d="M0,0 C16,0 16,4 24,12 C32,4 32,0 48,0" fill="#0F172A" />
          </svg>
        )}
        {children}
      </Flex>
    </InfoWindow>
  )
}
