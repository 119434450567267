import { cva } from "class-variance-authority"

export enum Feed {
  AppcastCPA = "appcast_cpa",
  AppcastCPC = "appcast_cpc",
  Talroo = "talroo",
  Cloudcrawler = "cloudcrawler",
  OneRedCent = "oneredcent",
  WhatJobs = "whatjobs",
  CareerWallet = "careerwallet",
  CareerBuilder = "careerbuilder",
  Recruitics = "recruitics",
  OrganicL = "organic_l",
  Workmaps = "workmaps",
}

export const debugFeedColorsClassnames = cva([], {
  variants: {
    logo: {
      true: "[&_.logo]:border-solid [&_.logo]:border-8",
      false: "",
    },
    // https://tailwindcss.com/docs/customizing-colors
    source: {
      [Feed.Cloudcrawler]: "border-blue-500",
      [Feed.AppcastCPA]: "border-green-500",
      [Feed.AppcastCPC]: "border-red-500",
      [Feed.Talroo]: "border-yellow-500",
      [Feed.WhatJobs]: "border-purple-500",
      [Feed.CareerBuilder]: "border-orange-500",
      [Feed.Recruitics]: "border-pink-500",
      [Feed.OneRedCent]: "border-red-800",
      [Feed.CareerWallet]: "border-yellow-800",
      [Feed.OrganicL]: "border-blue-300",
      [Feed.Workmaps]: "border-primary",
    } as Record<string, string>,
    badge: {
      true: "",
    },
  },
  compoundVariants: [
    {
      logo: true,
      source: Feed.Cloudcrawler,
      class: "[&_.logo]:border-blue-500",
    },
    {
      logo: true,
      source: Feed.AppcastCPA,
      class: "[&_.logo]:border-green-500",
    },
    {
      logo: true,
      source: Feed.AppcastCPC,
      class: "[&_.logo]:border-red-500",
    },
    {
      logo: true,
      source: Feed.Talroo,
      class: "[&_.logo]:border-yellow-500",
    },
    {
      logo: true,
      source: Feed.WhatJobs,
      class: "[&_.logo]:border-purple-500",
    },
    {
      logo: true,
      source: Feed.CareerBuilder,
      class: "[&_.logo]:border-orange-500",
    },
    {
      logo: true,
      source: Feed.Recruitics,
      class: "[&_.logo]:border-pink-500",
    },
    {
      logo: true,
      source: Feed.OneRedCent,
      class: "[&_.logo]:border-red-800",
    },
    {
      logo: true,
      source: Feed.CareerWallet,
      class: "[&_.logo]:border-yellow-800",
    },
    {
      logo: true,
      source: Feed.OrganicL,
      class: "[&_.logo]:border-blue-300",
    },
    {
      logo: true,
      source: Feed.Workmaps,
      class: "[&_.logo]:border-primary",
    },
    {
      badge: true,
      source: Feed.Cloudcrawler,
      class: "bg-blue-500",
    },
    {
      badge: true,
      source: Feed.AppcastCPA,
      class: "bg-green-500",
    },
    {
      badge: true,
      source: Feed.AppcastCPC,
      class: "bg-red-500",
    },
    {
      badge: true,
      source: Feed.Talroo,
      class: "bg-yellow-500",
    },
    {
      badge: true,
      source: Feed.WhatJobs,
      class: "bg-purple-500",
    },
    {
      badge: true,
      source: Feed.CareerBuilder,
      class: "bg-orange-500",
    },
    {
      badge: true,
      source: Feed.Recruitics,
      class: "bg-pink-500",
    },
    {
      badge: true,
      source: Feed.OneRedCent,
      class: "bg-red-800",
    },
    {
      badge: true,
      source: Feed.CareerWallet,
      class: "bg-yellow-800",
    },
    {
      badge: true,
      source: Feed.OrganicL,
      class: "bg-blue-300",
    },
    {
      badge: true,
      source: Feed.Workmaps,
      class: "bg-primary",
    },
  ],
})
