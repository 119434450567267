import { useQuery, useMutation, useQueryClient } from "@tanstack/react-query"
import type { User } from "@prisma/client"
import type { UserCaptureRequestPayload, UserCaptureBadRequestPayload } from "@/app/api/users/capture/schemas"
import type { ApiResponse, ApiResponseError } from "@/types"
import { api } from "@/lib/api"
import { useAnalytics } from "@/lib/frontend/hooks/useAnalytics"
import * as Sentry from "@sentry/nextjs"
import { useUser } from "@/components/UserContext"

interface UseContactInformationCaptureOptions {
  onError?: (error: Error | ApiResponseError<UserCaptureBadRequestPayload>) => void
}

/**
 * Hook that provides utilitiles to capture non-logged in user information.
 */
export default function useContactInformationCapture(options?: UseContactInformationCaptureOptions) {
  const analytics = useAnalytics()
  const queryClient = useQueryClient()
  const user = useUser()
  const { data: contactInfo } = useQuery({
    queryKey: ["contact-info", user],
    queryFn: () => {
      return {
        email: user?.email ?? localStorage?.getItem("email"),
        phoneNumber: user?.phone_number ?? localStorage?.getItem("phone_number"),
      }
    },
  })
  const mutation = useMutation({
    // When capturing the email in the CPA/direct modal as we redirect the user to the job, there can be a conflict as
    // we're trying to write at the same time. Allowing it to retry with a delay fixes the issue.
    retry: 3,
    retryDelay: 200,
    mutationKey: ["contact-info", "capture"],
    mutationFn: async (payload: UserCaptureRequestPayload) => {
      let identifer: string | undefined

      if ("email" in payload && typeof payload.email === "string") {
        identifer = payload.email
      } else if ("phone_number" in payload && typeof payload.phone_number === "string") {
        identifer = payload.phone_number
      } else if ("identifier" in payload && typeof payload.identifier === "string") {
        identifer = payload.identifier
      } else if (localStorage?.getItem("email")) {
        identifer = localStorage.getItem("email")!
      } else if (localStorage?.getItem("phone_number")) {
        identifer = localStorage.getItem("phone_number")!
      }

      const response = await api.post("/api/users/capture", {
        json: {
          ...payload,
          identifier: identifer,
          address: payload.address ?? localStorage.getItem("address") ?? undefined,
        },
      })
      const data = await response.json<ApiResponse<{ user: User }, UserCaptureBadRequestPayload>>()

      if (!data.ok) {
        throw data
      }

      // We need to await this so that the click is associated with the newly
      // created user. If this fails for whatever reason, let the user
      // advance.
      try {
        await analytics.identify(data.user.id, {
          email: data.user.email,
          firstName: data.user.first_name,
          lastName: data.user.last_name,
          createdAt: data.user.created_at,
          // Segment's spec says phone, but the Segment Klaviyo destination expect phoneNumber, so lets send both.
          phone: data.user.phone_number,
          phoneNumber: data.user.phone_number,
        })
      } catch (e) {
        console.warn("Failed to identify user", e, data)
        Sentry.captureException(e, {
          extra: { data },
        })
        throw e
      }

      // Save the data to local storage
      if (localStorage) {
        Object.entries(data.user).forEach(([key, value]) => {
          try {
            if (value) {
              localStorage.setItem(key, value.toString())
            }
          } catch (e) {
            // local storage can be full or blocked in some browsers. Silently
            // error and continue.
            console.warn(e)
          }
        })
      }

      // The query above needs to be manually revalidated as we are updating the underlying local storage and that does
      // not happen automatically.
      queryClient.invalidateQueries({ queryKey: ["contact-info"], exact: false })

      return data.user
    },
    onError: (error: any) => {
      Sentry.captureException(error)
      options?.onError?.(error)
    },
  })

  return {
    ...contactInfo,
    capture: mutation.mutateAsync,
  }
}
