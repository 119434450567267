import * as React from "react"
import { cva, type VariantProps } from "class-variance-authority"
import { cn } from "@/lib/frontend/shadcn"
import { flexVariants } from "./flex"
import { Slot } from "@radix-ui/react-slot"

// Card component
const cardVariants = cva(["rounded-lg border bg-card text-card-foreground p-2.5"])

export type CardProps = { asChild?: boolean; className?: string } & VariantProps<typeof cardVariants> &
  VariantProps<typeof flexVariants> &
  React.HTMLAttributes<HTMLDivElement>

export const Card = React.forwardRef(
  (
    {
      asChild,
      className,
      direction = "col",
      justify,
      align,
      wrap,
      grow,
      shrink,
      basis,
      gap,
      flex,
      fullWidth,
      ...props
    }: CardProps,
    ref
  ) => {
    const Comp = asChild ? Slot : "div"
    const computed = cardVariants()
    const componentClassName = cn(
      flexVariants({ direction, justify, align, wrap, grow, shrink, basis, gap, flex, fullWidth }),
      computed,
      className
    )

    // @ts-expect-error HTML types are hard
    return <Comp ref={ref} className={componentClassName} {...props} />
  }
)
Card.displayName = "Card"

// CardHeader component
const cardHeaderVariants = cva(["flex flex-col space-y-1.5 p-6"])

export type CardHeaderProps<C extends React.ElementType = "div"> = { as?: C } & VariantProps<
  typeof cardHeaderVariants
> &
  React.HTMLAttributes<React.ElementType<C>>

export const CardHeader = React.forwardRef(
  <C extends React.ElementType = "div">(
    { as, className, ...props }: CardHeaderProps<C>,
    ref: React.ForwardedRef<Element>
  ) => {
    const Component = as ?? "div"
    const computed = cardHeaderVariants()
    // @ts-expect-error HTML types are hard
    return <Component ref={ref} className={cn(computed, className)} {...props} />
  }
)
CardHeader.displayName = "CardHeader"

// CardTitle component
const cardTitleVariants = cva(["text-2xl font-semibold leading-none tracking-tight"])

export type CardTitleProps<C extends React.ElementType = "h3"> = { as?: C } & VariantProps<typeof cardTitleVariants> &
  React.HTMLAttributes<React.ElementType<C>>

export const CardTitle = React.forwardRef(
  <C extends React.ElementType = "h3">(
    { as, className, ...props }: CardTitleProps<C>,
    ref: React.ForwardedRef<Element>
  ) => {
    const Component = as ?? "h3"
    const computed = cardTitleVariants()
    // @ts-expect-error HTML types are hard
    return <Component ref={ref} className={cn(computed, className)} {...props} />
  }
)
CardTitle.displayName = "CardTitle"

// CardDescription component
const cardDescriptionVariants = cva(["text-sm text-muted-foreground"])

export type CardDescriptionProps<C extends React.ElementType = "p"> = { as?: C } & VariantProps<
  typeof cardDescriptionVariants
> &
  React.HTMLAttributes<React.ElementType<C>>

export const CardDescription = React.forwardRef(
  <C extends React.ElementType = "p">(
    { as, className, ...props }: CardDescriptionProps<C>,
    ref: React.ForwardedRef<Element>
  ) => {
    const Component = as ?? "p"
    const computed = cardDescriptionVariants()
    // @ts-expect-error HTML types are hard
    return <Component ref={ref} className={cn(computed, className)} {...props} />
  }
)
CardDescription.displayName = "CardDescription"

// CardContent component
const cardContentVariants = cva(["p-0 pt-0"])

export type CardContentProps<C extends React.ElementType = "div"> = { as?: C } & VariantProps<
  typeof cardContentVariants
> &
  VariantProps<typeof flexVariants> &
  React.HTMLAttributes<React.ElementType<C>>

export const CardContent = React.forwardRef(
  <C extends React.ElementType = "div">(
    { as, className, ...props }: CardContentProps<C>,
    ref: React.ForwardedRef<Element>
  ) => {
    const Component = as ?? "div"
    const computed = cardContentVariants()
    const componentClassName = cn(flexVariants({ direction: props.direction ?? "col", ...props }), computed, className)
    // @ts-expect-error HTML types are hard
    return <Component ref={ref} className={componentClassName} {...props} />
  }
)
CardContent.displayName = "CardContent"

// CardFooter component
const cardFooterVariants = cva(["flex items-center p-6 pt-0"])

export type CardFooterProps<C extends React.ElementType = "div"> = { as?: C } & VariantProps<
  typeof cardFooterVariants
> &
  React.HTMLAttributes<React.ElementType<C>>

export const CardFooter = React.forwardRef(
  <C extends React.ElementType = "div">(
    { as, className, ...props }: CardFooterProps<C>,
    ref: React.ForwardedRef<Element>
  ) => {
    const Component = as ?? "div"
    const computed = cardFooterVariants()
    // @ts-expect-error HTML types are hard
    return <Component ref={ref} className={cn(computed, className)} {...props} />
  }
)
CardFooter.displayName = "CardFooter"
