import { cn } from "@/lib/frontend/shadcn"
import { AdvancedMarker, GoogleMapsContext, useAdvancedMarkerRef } from "@vis.gl/react-google-maps"
import { Text } from "@/components/ui/text"
import { Button } from "@/components//ui/button"
import { Flex } from "@/components/ui/flex"
import { LabeledInput } from "@/components/ui/input"
import { IconCurrentLocation } from "@tabler/icons-react"
import { ToggleGroup, ToggleGroupItem } from "@/components/ui/toggle-group"
import { useLocationSearch } from "@/lib/frontend/hooks/useLocationSearch"
import { Autocomplete } from "@/components/ui/autocomplete"
import { useForm } from "react-hook-form"
import { useCallback, useContext, useEffect, useState } from "react"
import { useUserLocation } from "@/lib/frontend/hooks/useUserLocation"
import { useUserJobPreferences } from "@/lib/frontend/hooks/useUserJobPreferences"
import { useGeolocated } from "react-geolocated"
import { useLocalStorageValue } from "@react-hookz/web"
import { useAnalytics } from "@/lib/frontend/hooks/useAnalytics"
import { MapMarkerModal } from "./MapMarkerModal"
import { LayerType, useMapLayers } from "@/lib/frontend/hooks/useMapLayers"

type RefineYourLocationMarkerProps = {
  className?: string
  modalOpened?: boolean
}

const transitMethods = [
  {
    id: "🦶",
    label: "walk",
  },
  {
    id: "🚲",
    label: "bike",
  },
  {
    id: "🚇",
    label: "subway",
  },
  {
    id: "🚌",
    label: "bus",
  },
  {
    id: "🚗",
    label: "drive",
  },
]

export type RefineYourLocationData = {
  address?: string | undefined
  idealCommuteTime?: string | undefined
  transitMethods?: string[] | undefined
}

export const RefineYourLocationMarker: React.FC<RefineYourLocationMarkerProps> = ({ modalOpened }) => {
  const userLocation = useUserLocation()
  const { updateCommuteSettings } = useUserJobPreferences()
  const analytics = useAnalytics()
  const mapLayers = useMapLayers()

  const lat = userLocation.address?.lat ?? userLocation.userGeo?.lat
  const lng = userLocation.address?.lng ?? userLocation.userGeo?.lon

  const form = useForm<RefineYourLocationData>({
    defaultValues: {
      address: "",
      idealCommuteTime: "",
      transitMethods: [],
    },
  })

  const locationSearch = useLocationSearch({
    form: form as any,
    initialAddress: "",
    uiPlacement: "refine-your-location",
  })

  // `markerRef` and `marker` are needed to establish the connection between the marker and infowindow
  const [markerRef, marker] = useAdvancedMarkerRef()

  const { value: addressAlreadyProvided, set: setAddressProvided } = useLocalStorageValue(
    "refineYourLocationAddressProvided",
    {
      defaultValue: false,
      initializeWithValue: false,
    }
  )

  const { value: submitOrCloseButtonClicked, set: setSubmitOrCloseButton } = useLocalStorageValue(
    "refineYourLocationAddressProvided",
    {
      defaultValue: false,
      initializeWithValue: false,
    }
  )

  const { value: addressValue, set: setAddressValue } = useLocalStorageValue("addressBarValue", {
    defaultValue: "",
    initializeWithValue: false,
  })
  const [infoWindowShown, setInfoWindowShown] = useState(modalOpened && !submitOrCloseButtonClicked)

  useEffect(() => {
    setInfoWindowShown(modalOpened && !submitOrCloseButtonClicked)
  }, [modalOpened, submitOrCloseButtonClicked])

  // clicking the marker will toggle the infowindow
  const handleMarkerClick = useCallback(() => setInfoWindowShown((isShown) => !isShown), [])

  // if the maps api closes the infowindow, we have to synchronize our state
  const handleClose = useCallback(() => {
    setInfoWindowShown(false)
    setSubmitOrCloseButton(true)
    analytics.track("Refine Your Location Modal Closed", { addressAlreadyProvided: addressAlreadyProvided })
  }, [setInfoWindowShown, addressAlreadyProvided, analytics, setSubmitOrCloseButton])

  const map = useContext(GoogleMapsContext)?.map

  const [approximateCircle, setApproximateCircle] = useState<google.maps.Circle>()
  let labelPosition = undefined
  if (approximateCircle) {
    const approximateCircleBounds = approximateCircle.getBounds()
    const SW = approximateCircleBounds?.getSouthWest()
    const center = approximateCircle.getCenter()

    if (SW && center) {
      // TODO figure out positioning of label
      labelPosition = { lat: SW.lat(), lng: center.lng() }
    }
  }

  useEffect(() => {
    if (!map || !lat || !lng || addressValue) {
      return
    }

    const radius = 8000 // meters

    const circle = new google.maps.Circle({
      strokeColor: "#3b82f6",
      strokeOpacity: 0.8,
      strokeWeight: 2,
      fillColor: "#3b82f6",
      fillOpacity: 0.35,
      map,
      center: { lat: lat, lng: lng },
      radius,
    })

    setApproximateCircle(circle)

    return () => {
      circle.setMap(null)
      google.maps.event.clearListeners(map, "zoom_changed")
    }
  }, [map, lat, lng, addressValue])

  useEffect(() => {
    if (approximateCircle && addressValue) {
      approximateCircle.setMap(null)
    }
  }, [approximateCircle, addressValue])

  // track selected transit methods
  const selectedMethods = form.watch("transitMethods")

  const handleValueChange = (value: string[]) => {
    form.setValue("transitMethods", value)
    analytics.track("Transit Method Selected", { transitMethods: value })

    const updatedMapLayers: LayerType[] = []

    if (value.includes("subway") || value.includes("bus")) {
      updatedMapLayers.push("transit")
    }

    if (value.includes("bike")) {
      updatedMapLayers.push("bike")
    }

    if (updatedMapLayers.length > 0) {
      mapLayers.set(updatedMapLayers)
    }
  }

  const handleSelectAll = () => {
    form.setValue(
      "transitMethods",
      transitMethods.map((method) => method.label)
    )
    analytics.track("All Transit Method Selected")
    // Only going to show the transit layer here because the bike one is very messy
    mapLayers.set(["transit"])
  }

  const [addressKey, setAddressKey] = useState(0) // key to force re-render for address value

  const browserGeolocation = useGeolocated({
    positionOptions: {
      enableHighAccuracy: false,
    },
    suppressLocationOnMount: true,
    onSuccess: useCallback(
      (position: GeolocationPosition) => {
        const { latitude, longitude } = position.coords
        map?.panTo({ lat: latitude, lng: longitude })
        updateCommuteSettings.mutate({ lat: latitude, lng: longitude })
        setAddressValue("Current Location")
        setAddressProvided(true)
        setSubmitOrCloseButton(false)
        analytics.track("Current Location Button Clicked", {
          latitude: latitude,
          longitude: longitude,
        })

        setAddressKey((prev) => prev + 1) // force autocomplete re-render
      },
      [map, updateCommuteSettings, setAddressProvided, setAddressValue, analytics, setSubmitOrCloseButton]
    ),
  })

  const handleAddressOptionSubmit = (label: string) => {
    form.setValue("address", label)
    updateCommuteSettings.mutate({ address: label })
    setAddressValue(label)
    setAddressProvided(true)
    setSubmitOrCloseButton(false)
  }

  const handleSubmit = form.handleSubmit(async (data) => {
    setInfoWindowShown(false)
    try {
      await updateCommuteSettings.mutateAsync({ ...data })
    } catch (_e) {
      form.setError("root", { message: "An error occurred. Please try again." })
      return false
    }
    setSubmitOrCloseButton(true)
    analytics.track("Commute Settings Submitted", { commuteSettings: data })
  })

  if (!lat || !lng) {
    return null
  }

  return (
    <>
      {!addressValue && (
        <AdvancedMarker position={labelPosition} collisionBehavior="REQUIRED" zIndex={1000000}>
          <Text className="bg-slate-600 text-white font-bold rounded-md px-1.5 py-1 uppercase text-xs z-[1000] font-sans">
            Approximate
          </Text>
        </AdvancedMarker>
      )}

      <AdvancedMarker
        position={{
          lat: lat,
          lng: lng,
        }}
        collisionBehavior="REQUIRED"
        zIndex={1000000}
        ref={markerRef}
        onClick={(e) => {
          e.stop()
          handleMarkerClick()
          analytics.track("Refine Your Location Marker Clicked", { addressAlreadyProvided: addressAlreadyProvided })
        }}
      >
        <Flex direction="col" align="center" justify="center" className="absolute gap-2 top-6 bottom-0 left-0 right-0">
          <Text
            className={cn(
              "bg-white rounded-full border-blue-500 border-2 h-10 w-10 items-center flex justify-center drop-shadow-xl flex-shrink-0"
            )}
          >
            📍
          </Text>
          <Button
            variant="outline"
            className={cn("pointer-events-auto font-sans shadow-xl")}
            onClick={(e) => {
              e.stopPropagation() // prevent marker click
              handleMarkerClick()
              analytics.track("Refine Your Location Button Clicked", { addressAlreadyProvided: addressAlreadyProvided })
            }}
          >
            {addressValue ? "You are here!" : "Refine your location"}
          </Button>
        </Flex>
      </AdvancedMarker>

      {infoWindowShown && (
        <MapMarkerModal anchor={marker} onClose={handleClose} headerDisabled className="w-[425px]">
          <form onSubmit={handleSubmit} className={cn("gap-4 flex flex-col")}>
            <Text weight="semibold" className={cn("leading-6")}>
              Set Your Commute
            </Text>
            <hr className="-mx-3 -my-1" />
            <Text weight="semibold">From...</Text>
            <Flex align="center" className={cn("gap-2")}>
              <Autocomplete
                key={addressKey} // use key to force re-render for address value
                id="address-search"
                placeholder="Start typing your address"
                leftSection={false}
                options={[...(locationSearch.addressQuery.data ?? [])]}
                onInputChange={locationSearch.onAddressSearch}
                onValueChange={({ label }) => {
                  handleAddressOptionSubmit(label)
                }}
                isLoading={locationSearch.addressQuery.isFetching}
                className={cn("flex-grow")}
                defaultValue={addressValue ?? undefined}
              />
              <Button
                variant="outline"
                rounded="md"
                className={cn("h-10 w-10")}
                onClick={(e) => {
                  e.preventDefault() // prevent modal from closing
                  browserGeolocation.getPosition()
                }}
              >
                <IconCurrentLocation size={16} className={cn("flex-shrink-0")} />
              </Button>
            </Flex>
            <LabeledInput
              label="Ideal Commute Time"
              placeholder="Enter maximum number of minutes"
              {...form.register("idealCommuteTime")}
            />
            <Text weight="semibold">Transit</Text>

            <ToggleGroup
              type="multiple"
              onValueChange={handleValueChange}
              value={selectedMethods}
              className={cn("grid grid-cols-3 gap-2 w-full")}
            >
              <Button
                className="h-16 flex-col gap-2 flex uppercase text-xs"
                rounded="md"
                variant="outline"
                type="button"
                animatePress={false}
                onClick={handleSelectAll}
              >
                <div>🚀</div>
                <div>all</div>
              </Button>
              {transitMethods.map((transitMethod) => (
                <ToggleGroupItem
                  variant="outline"
                  rounded="md"
                  key={transitMethod.label}
                  value={transitMethod.label}
                  className={cn("uppercase text-xs flex-col h-16 gap-2 flex-grow w-full")}
                >
                  <div>{transitMethod.id}</div>
                  <div>{transitMethod.label}</div>
                </ToggleGroupItem>
              ))}
            </ToggleGroup>
            <hr className="-mx-3" />
            <Button fullWidth variant="dark" type="submit" rounded="md">
              Save changes
            </Button>
          </form>
        </MapMarkerModal>
      )}
    </>
  )
}
