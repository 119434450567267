import { cn } from "@/lib/frontend/shadcn"
import { MapControl as BaseMapControl, ControlPosition } from "@vis.gl/react-google-maps"

export { ControlPosition }

interface Props {
  position: ControlPosition
  className?: string
}

/**
 * Wrapper component for Google Map's control component. It allows you to place interactive features on the map. This
 * code must wrap it as the Google Map's component unsets a lot of app related global styles.
 */
export const MapControl: React.FC<React.PropsWithChildren<Props>> = ({ position, className, children }) => {
  return (
    <BaseMapControl position={position}>
      <div className={cn("font-sans", className)}>{children}</div>
    </BaseMapControl>
  )
}
