"use client"

import * as React from "react"
import * as LabelPrimitive from "@radix-ui/react-label"
import { cva, type VariantProps } from "class-variance-authority"

import { cn } from "@/lib/frontend/shadcn"

export const labelVariants = cva(
  "text-sm font-medium leading-none cursor-pointer peer-disabled:cursor-not-allowed peer-disabled:opacity-70",
  {
    variants: {
      fontWeight: {
        light: "font-light", // 300
        normal: "font-normal", // 400
        medium: "font-medium", // 500
        semibold: "font-semibold", // 600
        bold: "font-bold", // 700
      },
    },
  }
)

const Label = React.forwardRef<
  React.ElementRef<typeof LabelPrimitive.Root>,
  React.ComponentPropsWithoutRef<typeof LabelPrimitive.Root> & VariantProps<typeof labelVariants>
>(({ fontWeight, className, ...props }, ref) => (
  <LabelPrimitive.Root ref={ref} className={cn(labelVariants({ fontWeight }), className)} {...props} />
))
Label.displayName = LabelPrimitive.Root.displayName

export { Label }
