"use client"

import { useMap } from "@/components/MapProvider"
import { useAnalytics } from "@/lib/frontend/hooks/useAnalytics"
import { api } from "@/lib/api"
import { useWorkmapsContext, useDebouncedValue } from "@/lib/frontend/hooks"
import { useIsMedium } from "@/lib/frontend/util"
import { UseFormReturn } from "react-hook-form"
import { useUpdateEffect } from "@react-hookz/web"
import { useQuery } from "@tanstack/react-query"
import { useCallback, useState } from "react"
import type { PlaceAutocompleteResult, PlaceData } from "@googlemaps/google-maps-services-js"
import type { ApiResponse } from "@/types"
import useContactInformationCapture from "@/lib/frontend/hooks/useContactInformationCapture"
import { useGeolocated } from "react-geolocated"
import { usePortal } from "@/components/PortalProvider"
import { TrackedContactInfoCapture } from "@/components/ContactInfoCapture"
import { useStoreFeedLayer } from "./statsig"

type AddressFormState = Record<string, any> & {
  address: string
  place_id: string
}

export type AddressWidgetProps = {
  form: UseFormReturn<AddressFormState>
  toggle?: (id: string | null) => void
  initialAddress?: string
  uiPlacement?: string
}

type AppPlaceData = Pick<PlaceData, "geometry" | "adr_address">

export const useLocationSearch = ({ form, toggle, initialAddress, uiPlacement }: AddressWidgetProps) => {
  const { state, dispatch } = useWorkmapsContext()
  const { map } = useMap()
  const analytics = useAnalytics()
  const { emailCaptureV2GoogleLoginEnabled, refineYourLocation } = useStoreFeedLayer()
  const contactInfoModal = usePortal("contact-info-portal")

  const savedAddress = localStorage?.getItem("address") ?? ""
  const savedPlaceId = localStorage?.getItem("place_id") ?? ""
  const { capture, email, phoneNumber } = useContactInformationCapture()
  const watchedPlaceId = form.watch("place_id")

  const isMedium = useIsMedium()
  const [searchEnabled, setSearchEnabled] = useState(!!initialAddress)
  const [address, setAddress] = useState(savedAddress ?? initialAddress)
  const debouncedAddress = useDebouncedValue(address, 200)

  const addressQuery = useQuery({
    queryKey: ["address", debouncedAddress] as const,
    staleTime: 0,
    refetchOnMount: false,
    refetchOnWindowFocus: false,
    enabled: searchEnabled,
    queryFn: async ({ signal, queryKey: [, address] }) => {
      if (!address) return []

      const response = await api.get("/api/address", {
        signal,
        searchParams: {
          address,
          lat: state.lat,
          lng: state.lng,
        },
      })
      const data = await response.json<ApiResponse<{ result: PlaceAutocompleteResult[] }>>()

      if (!data.ok) {
        throw data
      }

      return data.result.map((address) => ({
        value: address.place_id,
        label: address.description,
      }))
    },
  })

  const placeQuery = useQuery({
    queryKey: ["place", watchedPlaceId] as const,
    staleTime: 0,
    refetchOnMount: false,
    refetchOnWindowFocus: false,
    enabled: !!watchedPlaceId,
    queryFn: async ({ signal, queryKey: [, placeId] }) => {
      const response = await api.get("/api/place", {
        signal,
        searchParams: {
          place_id: placeId,
        },
      })
      const data = await response.json<ApiResponse<{ result: AppPlaceData }>>()

      if (!data.ok) {
        throw data
      }

      return data.result
    },
  })

  const onOptionSubmit = (value: string) => {
    const address = addressQuery.data?.find((option) => option.value === value)?.label || ""
    form.setValue("address", address)
    form.setValue("place_id", value)
    analytics.track("Address Option Selected", {
      address,
      place_id: value,
    })
  }

  const onAddressSearch = (value: string) => {
    setAddress(value)
    setSearchEnabled(true)
  }

  const onAddressClear = () => {
    form.setValue("place_id", "")
    form.setValue("address", "")
    localStorage?.removeItem("address")
    localStorage?.removeItem("place_id")
    setAddress("")
    analytics.track("Address Search Cleared")
  }

  const { smsCaptureModal } = useStoreFeedLayer()

  const openEmailCaptureModal = useCallback(() => {
    const method = smsCaptureModal() ? "sms" : "email"
    contactInfoModal.open(
      <TrackedContactInfoCapture
        title="🔔 Get alerted as soon as new jobs drop"
        description={undefined}
        cta="Continue"
        method={method}
        showConsentToMarketingCheckbox={method === "sms"}
        onCapture={() => contactInfoModal.close()}
        onClose={() => contactInfoModal.close()}
        googleLoginEnabled={emailCaptureV2GoogleLoginEnabled()}
      />
    )
  }, [contactInfoModal, emailCaptureV2GoogleLoginEnabled, smsCaptureModal])

  const onSubmit = async () => {
    if (!placeQuery.data) {
      analytics.track("Place Data Not Loaded Error")
      console.warn("Place data has not loaded, but user submitted form.")
      return
    }

    // Move the map immediately so that the user gets feedback that their location search was successful
    const { lat, lng } = placeQuery.data.geometry.location
    map?.goTo({ lat, lng, zoom: 13 })
    dispatch({ lat, lng, zoom: 13 })
    toggle?.(null)
    localStorage?.setItem("address", form.getValues().address)
    localStorage?.setItem("place_id", form.getValues().place_id)
    setSearchEnabled(false)

    const identifier = email ?? phoneNumber

    if (identifier) {
      const user = await capture({
        identifier: identifier,
        address: form.getValues().address ?? undefined,
      })

      await analytics.identify(user.id, {
        email: user.email,
        firstName: user.first_name,
        lastName: user.last_name,
        createdAt: user.created_at,
      })
    } else if (!isMedium && !refineYourLocation()) {
      setTimeout(openEmailCaptureModal, 2_000)
    }

    analytics.track("Address Search Submitted")
  }

  useUpdateEffect(() => {
    if (placeQuery.data && form.getValues().place_id !== savedPlaceId) {
      onSubmit()
    }
  }, [placeQuery.data])

  const browserGeolocation = useGeolocated({
    positionOptions: {
      enableHighAccuracy: false,
    },
    suppressLocationOnMount: true,
    onSuccess: useCallback(
      (position: GeolocationPosition) => {
        const { latitude, longitude } = position.coords
        map?.goTo({ lat: latitude, lng: longitude })
        dispatch({ lat: latitude, lng: longitude })
        localStorage?.setItem("geolocationEnabled", position.timestamp.toString())
        analytics.track("Shared location", {
          latitude: latitude,
          longitude: longitude,
          uiPlacement,
        })

        if (!email && !isMedium) {
          setTimeout(openEmailCaptureModal, 2_000)
        }
      },
      [analytics, map, dispatch, uiPlacement, openEmailCaptureModal, email, isMedium]
    ),
  })

  return {
    form,
    address,
    addressQuery,
    placeQuery,
    onOptionSubmit,
    onAddressSearch,
    onAddressClear,
    onSubmit,
    savedAddress,
    savedPlaceId,
    openEmailCaptureModal,
    browserGeolocation,
  }
}
