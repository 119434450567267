import { formatOutboundJobUrl, uuidToNumberLossy } from "@/lib/util"
import { ApiListJob } from "@/types"
import { useMap } from "@/components/MapProvider"
import { useAnalytics } from "./useAnalytics"
import { useUser } from "@/components/UserContext"
import Cookies from "js-cookie"
import { fbTrack } from "@/lib/frontend/tracking"
import { useCallback, useMemo } from "react"
import useContactInformationCapture from "./useContactInformationCapture"
import { usePortal } from "@/components/PortalProvider"
import { useStatsigClient, useStoreFeedLayer } from "@/lib/frontend/hooks/statsig"
import { TrackedContactInfoCapture } from "@/components/ContactInfoCapture"
import { useLocalStorageValue } from "@react-hookz/web"
import { useSlug } from "./useSlug"
import { useSession } from "@/components/SessionContext"
import { customRandom } from "nanoid"
import seedrandom from "seedrandom"
import { useQuery } from "@tanstack/react-query"

export type MinimumRequiredJobFields = Pick<ApiListJob, "id" | "feed_hash" | "verified" | "source">

export default function useApply(
  job: MinimumRequiredJobFields,
  {
    onCapture,
    trackingParams = {},
  }: {
    onCapture?: () => void
    trackingParams?: Record<string, string>
  } = {}
) {
  const { session } = useSession()
  const analytics = useAnalytics()
  const emailModalPortal = usePortal("email-modal")
  const { emailCaptureV2GoogleLoginEnabled, emailCaptureDismissals } = useStoreFeedLayer()
  const { mapVisible } = useMap()
  const statsig = useStatsigClient()
  const trackedJobClicks = useLocalStorageValue<string[]>("workmaps.trackedJobClicks", {
    defaultValue: [],
    initializeWithValue: true,
  })

  // We generate the JobApplicationEvent.id client side and pass it to the backend on link click. We do this so that
  // when we fire the Clicked Link event below that the ID is consistent between backend and frontend events. The nanoid
  // is composed of a hash of the job.id + workmaps_device_id so that a bunch of apply links can be rendered in one
  // render cycle and be unique, which does not happen by default.
  const workmapsApplicationId = useMemo(
    () => nanoidFromUUIDs(job.id, session?.id ?? Cookies.get("workmaps_device_id")!),
    [job.id, session?.id]
  )
  const urlBuilder = formatOutboundJobUrl(job.id)
  urlBuilder.searchParams.set("map_visible", mapVisible ? "true" : "false")
  urlBuilder.searchParams.set("workmaps_application_id", workmapsApplicationId)
  const url = urlBuilder.toString()

  // We need to capture the email for all job postings that directly link to employers sites (aka job.verified) except
  // for parthers that do not allow for us to prompt for an email contractually.
  const jobRequiresCapture = job.verified && job.source !== "talroo"

  // We want to control how and how often we prompt for an email/phone number on a per slug page basis. We can do this
  // by setting a capture percentage in the Airtable and converting the user's workmaps_device_id to an int and if it's
  // less than the percent, show the modal.
  const currentSlug = useSlug()
  const slugCaptureMethod = currentSlug?.piiCaptureOptions.method
  const slugCapturePercent = currentSlug?.piiCaptureOptions.percent
  const slugPageDisablesCapture = useMemo(() => {
    if (slugCapturePercent === 0) {
      return true
    }

    if (slugCapturePercent === 100 || slugCapturePercent === undefined || !session?.id) {
      return false
    }

    return uuidToNumberLossy(session.id) * 100 <= slugCapturePercent
  }, [slugCapturePercent, session?.id])

  // If we do not have an email from a user, they must provide one to view CPA
  // and Organic job postings. If the user has been identified by analytics.js,
  // use that as a valid identifier, as users that landed on the site via an
  // email link should not need to provide their email again.
  const user = useUser()
  const { email: localStorageEmail, phoneNumber: localStoragePhoneNumber } = useContactInformationCapture()
  const email = user?.email || localStorageEmail || Cookies.get("ajs_user_id")
  const phoneNumber = user?.phone_number || localStoragePhoneNumber

  const handleConversion = useCallback(() => {
    if (trackedJobClicks.value?.includes(job.id)) {
      return
    }

    const decoded = JSON.parse(atob(job.feed_hash))

    // Track to Segment (Mixpanel, Statsig, etc.)
    analytics.track("Clicked Link", {
      job,
      url,
      source: decoded.source,
      rpc: decoded.rpc,
      job_application_event_id: workmapsApplicationId,
      job_id: job.id,
      ...trackingParams,
    })
    onCapture?.()
    trackedJobClicks.set((ids = []) => [...ids, job.id])
  }, [analytics, url, job, workmapsApplicationId, trackingParams, onCapture, trackedJobClicks])

  /**
   * When the user provides information to view CPA jobs, we must handle it
   * slightly differently.
   */
  const handleCapture = useCallback(
    ({
      openLink = true,
      ...params
    }: {
      method: "email" | "google" | "sms"
      consent_to_marketing: boolean
      openLink?: boolean
    }) => {
      analytics.track("shown_email_capture_provided_email", {
        jobId: job.id,
        url,
        ...trackingParams,
        ...params,
      })
      fbTrack("Lead", { content_name: "JobDetail:Apply-Email-Capture" })
      if (params.method !== "google") {
        // https://stackoverflow.com/a/70463940/471292
        if (openLink) {
          setTimeout(() => {
            window.open(url, "_blank")
          })
        }
      }
      handleConversion()
      emailModalPortal.close()
    },
    [analytics, handleConversion, job.id, url, trackingParams, emailModalPortal]
  )

  // This experiment is exposed by default in a list view and can slow down the site a lot if we do not cache the
  // exposure.
  const { value: modalsDismissed, set: setModalsDismissed } = useLocalStorageValue("emailModalsDismissed", {
    defaultValue: 0,
  })
  const { data: experimentDoesNotRequireCapture } = useQuery({
    initialData: true,
    queryKey: ["emailCaptureDismissals", modalsDismissed] as const,
    queryFn: ({ queryKey: [, modalsDismissed] }) => {
      const allowedDismissals = emailCaptureDismissals()
      return allowedDismissals > 0 && (modalsDismissed ?? 0) > allowedDismissals
    },
  })

  const handleDismissed = useCallback(() => {
    setModalsDismissed((count) => (count ?? 0) + 1)
  }, [setModalsDismissed])

  const handleSkip = useCallback(() => {
    analytics.track("Email Modal Skip Button Clicked")
    emailModalPortal.close()
    handleDismissed()
  }, [emailModalPortal, analytics, handleDismissed])

  const handleClickRequiringEmail = useCallback(
    (method?: "sms" | "email") => {
      if (!method) {
        // Experiment: for now, we are going to set the app up to capture more aggressively on certain, unskilled, slug
        // pages.
        if (slugCaptureMethod === "sms") {
          // In order for overrides to work on experiments, we have to double fetch the experiment 🤦
          statsig.getExperiment("sms_capture_modal_v2")
          method = statsig.getExperiment("sms_capture_modal_v2").get("is_enabled", false) ? "sms" : "email"
        } else if (slugCaptureMethod) {
          method = slugCaptureMethod
        } else {
          method = "email"
        }
      }

      emailModalPortal.open(
        <TrackedContactInfoCapture
          title={method === "sms" ? "Enter your number for job matches" : undefined}
          redirectUrl={url}
          onCapture={handleCapture}
          method={method}
          showConsentToMarketingCheckbox={method === "sms"}
          onClose={() => {
            emailModalPortal.close()
            handleDismissed()
          }}
          onSkip={handleSkip}
          googleLoginEnabled={emailCaptureV2GoogleLoginEnabled()}
        />
      )
    },
    [
      emailCaptureV2GoogleLoginEnabled,
      emailModalPortal,
      handleCapture,
      url,
      handleDismissed,
      statsig,
      handleSkip,
      slugCaptureMethod,
    ]
  )

  return {
    url,
    requireCapture:
      !email && !phoneNumber && jobRequiresCapture && !experimentDoesNotRequireCapture && !slugPageDisablesCapture,
    handleClickRequiringEmail,
    handleConversion,
    handleCapture,
    handleDismissed,
  }
}

function nanoidFromUUIDs(jobId: string, workmapsDeviceId: string) {
  const rng = seedrandom(jobId + workmapsDeviceId)
  const customNanoid = customRandom("abcdefghijklmnopqrstuvwxyzABCDEFGHIJKLMNOPQRSTUVWXYZ0123456789", 13, (size) => {
    const randomBytes = new Uint8Array(size)
    for (let i = 0; i < size; i++) {
      randomBytes[i] = Math.floor(rng() * 256)
    }
    return randomBytes
  })
  return customNanoid()
}
