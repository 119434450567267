import React from "react"
import { Text } from "@/components/ui/text"
import { cn } from "@/lib/frontend/shadcn"

interface Props {
  className?: string
}

export const TermsOfServiceDisclaimer: React.FC<Props> = ({ className }) => {
  return (
    <Text size="xs" className={className}>
      By clicking continue, you agree to the Workmaps.com{" "}
      <a href="https://www.searchpartyhq.com/privacy" className={cn("underline")} target="_blank">
        Privacy Policy
      </a>{" "}
      and{" "}
      <a href="https://www.searchpartyhq.com/terms" className={cn("underline")} target="_blank">
        Terms of Service
      </a>
      . You agree to be contacted by Workmaps and can unsubscribe at any time.
    </Text>
  )
}
