"use client"

function isProduction() {
  const url = new URL(window.location.href)
  return url.hostname === "workmaps.com" || url.searchParams.has("debug_forceprod")
}

export function fbTrack(eventName: string, props?: any) {
  /* FBPixel is hard-coded atm -- we want to limit what we send to production
     setting up an additional environment with it's own key doesn't look easy
     There is the ability to filter on the FacebookAd-Dashboard as an alternative
    */
  if (!isProduction()) return

  // Leaving debug on while we test and prototype
  // console.log(`tracking.fbTrack [${eventName}]`, { props })

  const fbq = window["fbq" as any] as any
  if (fbq) {
    fbq("track", eventName, props)
  }
}

// TODO Remove this once we remove the Google Analytics pixel. This tracking has
// been moved server side.
type GoogleConversionEventArgs = {
  value: number | string | null
  currency?: string
}

type GoogleConversionEvent = { send_to: string } & Partial<GoogleConversionEventArgs>

export const trackGoogleConversion = (event?: GoogleConversionEventArgs) => {
  const evt: GoogleConversionEvent = {
    send_to: "AW-11067305573/2YVsCMW428kYEOXcpp0p",
  }

  // https://developers.google.com/tag-platform/devguides/conversions
  if (event?.value) {
    evt.value = Number(event.value)
    evt.currency = event.currency || "USD"
  }

  // console.log("tracking.trackGoogleConversion", evt)
  if (!isProduction()) return

  const gtag = window["gtag" as any] as any
  gtag?.("event", "conversion", evt)
}
