import { Separator } from "@/components/ui/separator"
import { cn } from "@/lib/frontend/shadcn"
import { ToggleGroup, ToggleGroupItem } from "@/components/ui/toggle-group"
import { IconBike, IconLayersSubtract, IconTrain } from "@tabler/icons-react"
import { MapControl, ControlPosition } from "@/components/map/MapControl"
import { Popover, PopoverContent, PopoverTrigger } from "@/components/ui/popover"
import { Button } from "@/components/ui/button"
import { Avatar, AvatarFallback } from "@/components/ui/avatar"
import { useMapLayers } from "@/lib/frontend/hooks/useMapLayers"
import { useIsMedium } from "@/lib/frontend/util"

interface Props {
  className?: string
}

export const MapLayerControl: React.FC<Props> = () => {
  const mapLayers = useMapLayers()
  const isMedium = useIsMedium()

  return (
    <MapControl position={isMedium ? ControlPosition.TOP_LEFT : ControlPosition.TOP_CENTER}>
      <Popover>
        <PopoverTrigger asChild>
          <Button variant="white" className={cn("mt-2 mr-2 max-sm:ml-2 pl-2 pr-3 max-md:mt-16 shadow shadow-black")}>
            <Avatar className={cn("h-6 w-6 mr-2 text-center")}>
              <AvatarFallback className={cn("text-xs bg-primary")}>
                <IconLayersSubtract size={16} />
              </AvatarFallback>
            </Avatar>
            Layers
          </Button>
        </PopoverTrigger>
        <PopoverContent side="bottom" className={cn("p-2 rounded-md ml-2 md:ml-4")}>
          <ToggleGroup
            type="multiple"
            className={cn("flex flex-col gap-2 bg-white text-sm w-24 align-start")}
            value={mapLayers.enabledLayers}
            onValueChange={mapLayers.set}
          >
            <LayerOption label="Transit" value="transit" icon={<IconTrain size={16} />} />
            <Separator />
            <LayerOption label="Bike" value="bike" icon={<IconBike size={16} />} />
          </ToggleGroup>
        </PopoverContent>
      </Popover>
    </MapControl>
  )
}

const LayerOption: React.FC<{ label: React.ReactNode; value: string; icon: React.ReactNode }> = ({
  label,
  value,
  icon,
}) => {
  return (
    <ToggleGroupItem value={value} className={cn("flex flex-row font-bold w-full pl-2 text-left")} fullWidth>
      <Avatar className={cn("h-6 w-6 mr-2 text-center justify-self-start")}>
        <AvatarFallback className={cn("text-xs bg-primary")}>{icon}</AvatarFallback>
      </Avatar>
      <span className="w-full">{label}</span>
    </ToggleGroupItem>
  )
}
