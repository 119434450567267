import React from "react"
import { cva, VariantProps } from "class-variance-authority"
import { cn } from "@/lib/frontend/shadcn"

const spinnerVariants = cva("border-2 rounded-full animate-spin", {
  variants: {
    color: {
      primary: "border-primary border-t-transparent",
      white: "border-white border-t-transparent",
    },
    size: {
      xs: "w-3 h-3",
      sm: "w-4 h-4",
      md: "w-5 h-5",
      lg: "w-10 h-10 border-4",
      xl: "w-20 h-20 border-4",
    },
  },
  defaultVariants: {
    color: "primary",
    size: "md",
  },
})

interface Props extends VariantProps<typeof spinnerVariants> {
  className?: string
}

export const Spinner: React.FC<Props> = ({ className, color, size }) => {
  return (
    <div className="flex items-center justify-center">
      <div className={cn(spinnerVariants({ className, color, size }))}></div>
    </div>
  )
}
