"use client"

import * as React from "react"
import * as CheckboxPrimitive from "@radix-ui/react-checkbox"
import { Check } from "lucide-react"

import { Label } from "./label"
import { cn } from "@/lib/frontend/shadcn"

export interface CheckboxProps extends React.ComponentPropsWithoutRef<typeof CheckboxPrimitive.Root> {
  hookFormCompatible?: boolean
}

export const Checkbox = React.forwardRef<React.ElementRef<typeof CheckboxPrimitive.Root>, CheckboxProps>(
  ({ className, onChange, onCheckedChange, hookFormCompatible, ...props }, ref) => {
    return (
      <CheckboxPrimitive.Root
        ref={ref}
        className={cn(
          [
            "peer h-5 w-5 shrink-0 rounded-sm border border-input  disabled:cursor-not-allowed disabled:opacity-50 data-[state=checked]:bg-primary data-[state=checked]:text-primary-foreground",
            "focus-visible:outline-none focus-visible:ring-2 focus-visible:ring-ring focus-visible:ring-offset-2",
          ],
          className
        )}
        onCheckedChange={(checked) => {
          onCheckedChange?.(checked)
          if (hookFormCompatible) {
            onChange?.({
              target: {
                // @ts-expect-error `checked` is not a valid prop on a div
                value: checked,
                name: props.name, // assuming `name` is passed as a prop to `LabeledCheckbox`
              },
            })
          }
        }}
        {...props}
      >
        <CheckboxPrimitive.Indicator className={cn("flex items-center justify-center text-current")}>
          <Check className="h-5 w-5" />
        </CheckboxPrimitive.Indicator>
      </CheckboxPrimitive.Root>
    )
  }
)
Checkbox.displayName = CheckboxPrimitive.Root.displayName

export interface LabeledCheckboxProps extends CheckboxProps {
  label: React.ReactNode
  error?: React.ReactNode
  direction?: "row" | "row-reverse"
  fontWeight?: "light" | "normal" | "medium" | "semibold" | "bold"
}

export const LabeledCheckbox = React.forwardRef<React.ElementRef<typeof CheckboxPrimitive.Root>, LabeledCheckboxProps>(
  ({ fontWeight, className, label, error, direction = "row", ...props }, ref) => {
    const fieldId = React.useId()

    return (
      <div
        className={cn(
          "flex",
          direction === "row" ? "flex-row" : "flex-row-reverse",
          "gap-2",
          "items-center",
          className
        )}
      >
        <Checkbox id={fieldId} ref={ref} {...props} />
        <Label fontWeight={fontWeight} htmlFor={fieldId}>
          {label}
        </Label>
      </div>
    )
  }
)
LabeledCheckbox.displayName = CheckboxPrimitive.Root.displayName
