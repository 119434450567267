import * as React from "react"
import { cn } from "@/lib/frontend/shadcn"

const Skeleton = React.forwardRef<HTMLDivElement, React.HTMLAttributes<HTMLDivElement>>(
  ({ className, ...props }, ref) => {
    return <div ref={ref} className={cn("animate-pulse rounded-md bg-muted-foreground/20", className)} {...props} />
  }
)

Skeleton.displayName = "Skeleton"

export { Skeleton }
