import { CategoryOption, JobCategory, ApiStoreSearchResult } from "@/types"

// // Sorted by frequency of use in the database:
//
// SELECT category, COUNT(*) AS category_count
// FROM (
//   SELECT jsonb_array_elements_text(search_params -> 'categories') AS category
//   FROM your_table
// ) AS subquery
// GROUP BY category
// ORDER BY category_count DESC;

export const categories: CategoryOption[] = [
  { value: "fast-food", label: "Fast Food", emoji: "🍔" },
  { value: "casual-dining", label: "Casual Dining", emoji: "🍴" },
  { value: "fast-casual", label: "Fast Casual", emoji: "🍽️" },
  { value: "department-store", label: "Department Store", emoji: "🏬" },
  { value: "grocery-stores", label: "Grocery Stores", emoji: "🛒" },
  { value: "beauty", label: "Beauty", emoji: "💄" },
  { value: "electronics", label: "Electronics", emoji: "🔌" },
  { value: "discount", label: "Discount", emoji: "💸" },
  { value: "convenience", label: "Convenience", emoji: "🏪" },
  { value: "pizzerias", label: "Pizzerias", emoji: "🍕" },
  { value: "pets", label: "Pets", emoji: "🐾" },
  { value: "sporting-goods", label: "Sporting Goods", emoji: "🏈" },
  { value: "coffee-shop", label: "Coffee Shop", emoji: "☕" },
  { value: "clothing", label: "Clothing", emoji: "👕" },
  { value: "diner", label: "Diner", emoji: "🍽️" },
  { value: "arts-&-crafts", label: "Arts & Crafts", emoji: "🖌️" },
  { value: "pharmacy", label: "Pharmacy", emoji: "💊" },
  { value: "gas", label: "Gas", emoji: "⛽" },
  { value: "warehouse", label: "Warehouse", emoji: "🏭" },
  { value: "customer-service", label: "Customer Service", emoji: "📞" },
  { value: "cleaning-&-janitorial", label: "Cleaning & Janitorial", emoji: "🧹" },
  { value: "shopping", label: "Shopping", emoji: "🛍️" },
  { value: "delivery", label: "Delivery", emoji: "📦" },
  { value: "caregiving", label: "Caregiving", emoji: "🧑‍⚕️" },
  { value: "security", label: "Security", emoji: "🔒" },
  { value: "automotive", label: "Automotive", emoji: "🚘" },
  { value: "hobbies", label: "Hobbies", emoji: "🎨" },
  { value: "banking", label: "Banking", emoji: "🏦" },
  { value: "merchandising-and-marketing", label: "Merchandising and Marketing", emoji: "📈" },
  { value: "furniture", label: "Furniture", emoji: "🛋️" },
  { value: "tutoring", label: "Tutoring", emoji: "📚" },
  { value: "home-improvement", label: "Home Improvement", emoji: "🛠️" },
  { value: "driving", label: "Driving", emoji: "🚗" },
  { value: "substitute-teacher", label: "Substitute Teacher", emoji: "👨‍🏫" },
  { value: "trucking", label: "Trucking", emoji: "🚚" },
  { value: "appliances", label: "Appliances", emoji: "🧺" },
  { value: "medical", label: "Medical", emoji: "🏥" },
  { value: "teacher", label: "Teacher", emoji: "👩‍🏫" },
  { value: "hvac", label: "HVAC", emoji: "❄️" },
  { value: "food", label: "Food", emoji: "🥗" },
  { value: "insurance", label: "Insurance", emoji: "📄" },
  { value: "hotel", label: "Hotel", emoji: "🏨" },
  { value: "cannabis", label: "Cannabis", emoji: "🍃" },
  { value: "hardware-store", label: "Hardware Store", emoji: "🔨" },
  { value: "military", label: "Military", emoji: "🪖" },
  { value: "sales", label: "Sales", emoji: "💰" },
  { value: "construction", label: "Construction", emoji: "🏗️" },
  { value: "miscellaneous", label: "Miscellaneous", emoji: "🔍" },
  { value: "music", label: "Music", emoji: "🎵" },
  { value: "waste-management", label: "Waste Management", emoji: "♻️" },
  { value: "office", label: "Office", emoji: "🏢" },
  { value: "electrical", label: "Electrical", emoji: "⚡" },
  { value: "plumbing", label: "Plumbing", emoji: "🔧" },
  { value: "machine-operator", label: "Machine Operator", emoji: "🛠️" },
  { value: "counseling-&-therapy", label: "Counseling & Therapy", emoji: "💬" },
  { value: "salon-&-beauty", label: "Salon & Beauty", emoji: "💇" },
  { value: "entertainment", label: "Entertainment", emoji: "🎭" },
  { value: "senior-living", label: "Senior Living", emoji: "👵" },
  { value: "roofing", label: "Roofing", emoji: "🏠" },
  { value: "utilities", label: "Utilities", emoji: "💡" },
  { value: "parking-attendant", label: "Parking Attendant", emoji: "🚗" },
  { value: "forestry", label: "Forestry", emoji: "🌲" },
  { value: "books", label: "Books", emoji: "📚" },
  { value: "aerospace", label: "Aerospace", emoji: "🚀" },
  { value: "gyms", label: "Gyms", emoji: "🏋️" },
  { value: "installation", label: "Installation", emoji: "🔧" },
  { value: "uncategorized", label: "Uncategorized", emoji: "❓" },
  { value: "shoes", label: "Shoes", emoji: "👟" },
  { value: "recycling", label: "Recycling", emoji: "♻️" },
  { value: "airline", label: "Airline", emoji: "✈️" },
  { value: "plant-operator", label: "Plant Operator", emoji: "🏭" },
  { value: "mechanic", label: "Mechanic", emoji: "🔧" },
  { value: "movie-theatre", label: "Movie Theatre", emoji: "🎬" },
]

export const jobCategories: JobCategory[] = [
  { value: "allowsFullTime", label: "Full-Time", section: "Shift Preference" },
  { value: "allowsPartTime", label: "Part-Time", section: "Shift Preference" },
  // { value: "allowsFourdayWorkWeek", label: "4 Day Work Week", section: "Shift Preference" },
  { value: "overnightShift", label: "Overnight Shifts", section: "Shift Preference" },
  { value: "requireWeekendShift", label: "Weekend Shifts", section: "Shift Preference" },
  { value: "benefitHealthInsurance", label: "Health Insurance", section: "Benefits Offered" },
  { value: "allowsRemote", label: "Remote", section: "Benefits Offered" },
  { value: "allowsMinor", label: "Hires Under 18", section: "Age Requirement" },
  { value: "require21Older", label: "21+ Years Old", section: "Age Requirement" },
  { value: "requireCommercialDriversLicense", label: "CDL Required", section: "Additional Requirement" },
  { value: "requireDriversLicense", label: "Driver's License", section: "Additional Requirement" },
  { value: "requireDiploma", label: "High School Diploma", section: "Additional Requirement" },
  { value: "requireResume", label: "Resume Required", section: "Additional Requirement" },
  { value: "unionMembership", label: "Union", section: "Additional Requirement" },
]

export const jobCategoriesSections = [
  "Shift Preference",
  "Benefits Offered",
  "Age Requirement",
  "Additional Requirement",
] as const

export const jobCategoriesBySection = (section: (typeof jobCategoriesSections)[number]) => {
  const filteredCategories = jobCategories.filter((category) => category.section === section)

  if (!filteredCategories.length) {
    return null
  }

  return filteredCategories
}

const allDisplayableCategoryKeys = jobCategories.reduce((acc: string[], category) => {
  if (category.label && category.section) {
    acc.push(category.value)
  }

  return acc
}, [])

export const hasAnyDisplayableJobCategories = (jobCategories: string[]) =>
  jobCategories.length > 0 && jobCategories.some((category) => allDisplayableCategoryKeys.includes(category))

export const uniqueJobCategories = (store: ApiStoreSearchResult) => {
  const jobs = store.jobs
  return jobCategories.filter((jobCategory) => jobs.some((job) => job.job_categories?.includes(jobCategory.value)))
}
