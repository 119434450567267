import { useSession } from "@/components/SessionContext"
import { useUser } from "@/components/UserContext"
import { InitialLocationGeoData } from "@/types"
import { useQuery } from "@tanstack/react-query"

export function useUserLocation() {
  const user = useUser()
  const session = useSession()
  const { data: userGeo } = useQuery<InitialLocationGeoData>({ queryKey: ["user", "geo"] })

  return {
    userGeo: userGeo!,
    address: {
      lat: (user?.address_lat ?? session?.session?.addressLat) as number | null,
      lng: (user?.address_lng ?? session?.session?.addressLng) as number | null,
      address: user?.address ?? session?.session?.address,
    },
  }
}
