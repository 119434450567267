import * as React from "react"
import { Label } from "./label"

import { cn } from "@/lib/frontend/shadcn"
import { cva } from "class-variance-authority"

export interface InputProps extends React.InputHTMLAttributes<HTMLInputElement> {}

export const inputVariants = cva(
  cn(
    "flex h-10 w-full rounded-md text-sm bg-background px-2 py-2",
    "border border-input",
    "ring-offset-background",
    "file:border-0 file:bg-transparent file:text-sm file:font-medium",
    "placeholder:text-muted-foreground",
    "focus-visible:outline-none focus-visible:border-primary",
    "disabled:cursor-not-allowed disabled:opacity-50"
  ),
  {
    variants: {
      relativeFocus: {
        true: "focus-within:border-primary",
      },
    },
  }
)

export const Input = React.forwardRef<HTMLInputElement, LabeledInputProps>(
  ({ className, type, label, error, containerClassName, ...props }, ref) => {
    return <input type={type} className={inputVariants({ className })} ref={ref} {...props} />
  }
)
Input.displayName = "Input"

export interface LabeledInputProps extends React.InputHTMLAttributes<HTMLInputElement> {
  label?: React.ReactNode
  error?: React.ReactNode
  containerClassName?: string
}

export const LabeledInput = React.forwardRef<HTMLInputElement, LabeledInputProps>(
  ({ className, type, label, error, containerClassName, ...props }, ref) => {
    const fieldId = props.id ?? props.name

    return (
      <div className={cn("flex flex-col justify-center gap-2", containerClassName)}>
        {label && <Label htmlFor={fieldId}>{label}</Label>}
        <Input
          type={type}
          className={cn(className)}
          ref={ref}
          id={fieldId}
          aria-errormessage={fieldId && error ? `${fieldId}-error-message` : undefined}
          {...props}
        />
        {/* Keep the error field mounted with a height so that a single line error message does not shift the layout */}
        <div
          id={fieldId ? `${fieldId}-error-message` : undefined}
          className={cn("text-xs text-destructive h-4", !error && "hidden")}
        >
          {error}
        </div>
      </div>
    )
  }
)
LabeledInput.displayName = "LabeledInput"
