import * as React from "react"
import { cva, type VariantProps } from "class-variance-authority"
import { cn } from "@/lib/frontend/shadcn"

const badgeVariants = cva(
  "inline-flex items-center rounded-md border px-2.5 py-0.5 text-xs font-bold transition-colors focus:outline-none focus:ring-2 focus:ring-ring focus:ring-offset-2",
  {
    variants: {
      color: {
        primary: "bg-primary border-primary text-primary-foreground",
        secondary: "bg-secondary border-secondary text-secondary-foreground",
        muted: "bg-muted border-muted text-muted-foreground",
        success: "bg-success border-success text-success-foreground",
        destructive: "bg-destructive border-destructive text-destructive-foreground",
      },
      outlined: {
        true: "border border-solid bg-transparent",
      },
      size: {
        xss: "text-[11px] px-1 py-0",
        xs: "text-xs px-2 py-0.5",
        sm: "text-sm px-2.5 py-0.5",
        md: "text-base px-3 py-1",
        lg: "text-lg px-4 py-1.5",
      },
      radius: {
        none: "rounded-none",
        sm: "rounded-sm",
        md: "rounded-md",
        lg: "rounded-lg",
        full: "rounded-full",
      },
      circle: {
        true: "rounded-full",
      },
      fullWidth: {
        true: "w-full",
      },
    },
    compoundVariants: [
      {
        outlined: true,
        color: "primary",
        className: "text-primary",
      },
      {
        outlined: true,
        color: "success",
        className: "text-success border-primary",
      },
    ],
    defaultVariants: {
      color: "primary",
      outlined: false,
      size: "md",
      radius: "md",
    },
  }
)

export type BadgeProps = React.HTMLAttributes<HTMLDivElement> & VariantProps<typeof badgeVariants>

const Badge = React.forwardRef<HTMLDivElement, BadgeProps>(
  ({ className, color, outlined = false, size, radius, circle, fullWidth, ...props }, ref) => {
    const badgeClasses = badgeVariants({ color, outlined, size, radius, ...(circle && { radius: "full" }), fullWidth })
    return <div ref={ref} className={cn(badgeClasses, className)} {...props} />
  }
)

Badge.displayName = "Badge"

export { Badge, badgeVariants }
