const gradientColors = [
  "bg-gradient-to-tl from-red-100 to-orange-200", // A
  "bg-gradient-to-tl from-yellow-200 to-green-300", // B
  "bg-gradient-to-tl from-emerald-200 to-cyan-300", // C
  "bg-gradient-to-tl from-fuchsia-100 to-rose-200", // D
  "bg-gradient-to-tl from-teal-100 to-sky-200", // E
  "bg-gradient-to-tl from-blue-200 to-indigo-300", // F
  "bg-gradient-to-tl from-violet-100 to-purple-300", // G
  "bg-gradient-to-tl from-amber-200 to-lime-300", // H
  "bg-gradient-to-tl from-rose-100 to-red-300", // I
  "bg-gradient-to-tl from-orange-200 to-amber-300", // J
  "bg-gradient-to-tl from-yellow-100 to-lime-300", // K
  "bg-gradient-to-tl from-green-200 to-emerald-300", // L
  "bg-gradient-to-tl from-teal-100 to-cyan-300", // M
  "bg-gradient-to-tl from-sky-200 to-blue-300", // N
  "bg-gradient-to-tl from-indigo-100 to-violet-300", // O
  "bg-gradient-to-tl from-pink-200 to-red-300", // P
  "bg-gradient-to-tl from-pink-100 to-rose-300", // Q
  "bg-gradient-to-tl from-orange-100 to-yellow-200", // R
  "bg-gradient-to-tl from-amber-100 to-yellow-300", // S
  "bg-gradient-to-tl from-lime-200 to-green-300", // T
  "bg-gradient-to-tl from-emerald-100 to-teal-300", // U
  "bg-gradient-to-tl from-cyan-200 to-sky-300", // V
  "bg-gradient-to-tl from-blue-100 to-violet-200", // W
  "bg-gradient-to-tl from-violet-200 to-fuchsia-300", // X
  "bg-gradient-to-tl from-fuchsia-100 to-pink-300", // Y
  "bg-gradient-to-tl from-rose-200 to-purple-300", // Z
]

const alphaVal = (s: string) => s.toLowerCase().charCodeAt(0) - 97

const getGradientColor = (letter: string) => {
  const index = alphaVal(letter)
  return gradientColors[index] || "bg-gradient-to-tl from-slate-100 to-gray-300"
}

export const getLogoUrl = (employerLogoUrl: string | undefined) => {
  return employerLogoUrl === "https://imagedelivery.net/0GdndQHAiaWREXFh0K7QTg/logos/attr5rbn5gpJi80yC/120x120" // briefcase image link
    ? undefined
    : employerLogoUrl
}

export const getEmployerName = (employerName: string) => {
  const employerNameFirstLetter = employerName.charAt(0)
  const gradientColor = getGradientColor(employerNameFirstLetter)
  return { employerNameFirstLetter, gradientColor }
}
