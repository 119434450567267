import type { EnrichedJob, EnrichedStore } from "@/types"
import { Flex } from "@/components/ui/flex"
import { Text } from "@/components/ui/text"
import { Button, ButtonAnchor } from "@/components/ui/button"
import { Badge } from "@/components/ui/badge"
import { cn } from "@/lib/frontend/shadcn"
import { AppcastApplyLink } from "../AppcastApplyLink"
import { useAnalytics } from "@/lib/frontend/hooks/useAnalytics"
import { decimalToNumber, payRangeTextContent } from "@/lib/util"
import { jobCategoriesSections, uniqueJobCategories } from "@/lib/shared/categories"
import { Card } from "@/components/ui/card"
import { useLocalStorageValue } from "@react-hookz/web"

type SelectedPinContentProps = {
  store: EnrichedStore
  job: Omit<EnrichedJob, "store">
  handleClose: () => void
}

export const SelectedPinContent: React.FC<SelectedPinContentProps> = ({ job, store }) => {
  const analytics = useAnalytics()
  const jobCategories = uniqueJobCategories(store)

  const uiPlacement = "Map Pin Modal"

  const jobCategoriesBySection = (section: string) => {
    const filteredJobCategoriesBySection = jobCategories.filter(
      (jobCategory) => jobCategory.section === section && job.job_categories.includes(jobCategory.value)
    )

    if (filteredJobCategoriesBySection.length === 0) {
      return null
    }

    return (
      <Card key={section} className={cn("p-3 rounded-[.625rem]")}>
        <Text weight="semibold" className={cn("m-0 mb-3")}>
          {filteredJobCategoriesBySection.length > 1 && section !== "Benefits Offered" ? section + "s" : section}
        </Text>
        <Flex className={cn("gap-2 flex-wrap")}>
          {filteredJobCategoriesBySection
            .sort((a, b) => a.label.localeCompare(b.label))
            .map((jobCategory, index) => (
              <Badge
                key={index}
                size="xs"
                outlined
                className={cn("text-gray-800 border-gray-200 font-medium bg-muted whitespace-nowrap")}
              >
                {jobCategory.label}
              </Badge>
            ))}
        </Flex>
      </Card>
    )
  }

  const { value: dislikedJobs, set: setDislikedJobs } = useLocalStorageValue<string[]>("dislikedJobs", {
    defaultValue: [] as string[],
    initializeWithValue: false,
  })

  const { value: likedJobs, set: setLikedJobs } = useLocalStorageValue<string[]>("likedJobs", {
    defaultValue: [] as string[],
    initializeWithValue: false,
  })

  const onDislikeButtonClick = () => {
    // check to see if job was liked
    if (likedJobs?.includes(job.id)) {
      setLikedJobs(likedJobs.filter((id) => id !== job.id))
    }

    // add to dislikedJobs array if not there
    if (!dislikedJobs?.includes(job.id)) {
      setDislikedJobs([...(dislikedJobs ?? []), job.id])
    }

    analytics.track("Dislike Button Clicked", { jobId: job.id })
  }

  const onLikeButtonClick = () => {
    // check to see if job was disliked
    if (dislikedJobs?.includes(job.id)) {
      setDislikedJobs(dislikedJobs.filter((id) => id !== job.id))
    }

    // add to likedJobs array if not there
    if (!likedJobs?.includes(job.id)) {
      setLikedJobs([...(likedJobs ?? []), job.id])
    }
    analytics.track("Like Button Clicked", { jobId: job.id })
  }

  return (
    <>
      <Flex direction="column">
        <Text weight="semibold">{job.title}</Text>
        <Text weight="medium" className="text-muted-foreground">
          {store.employer.title}
        </Text>
      </Flex>
      <Flex direction="col" className={cn("gap-2.5 grow", job.job_categories.length === 0 && "hidden")}>
        {jobCategoriesSections.map((section) => jobCategoriesBySection(section))}
      </Flex>
      <Flex align="center" gap="sm">
        {/* <Button variant="outline" rounded="md" fullWidth>
          Save
        </Button> */}

        <AppcastApplyLink
          job={job}
          uiPlacement={uiPlacement}
          fullWidth
          variant="dark"
          size="default"
          buttonText="Apply Now"
          className="rounded-md"
        />
      </Flex>
      <hr />
      <Text size="sm" weight="semibold" className="text-muted-foreground">
        More Details
      </Text>
      <Flex direction="column" className={cn("gap-1.5")}>
        {job.pay_min && (
          <Badge outlined color="secondary" size="sm" radius="full" className={cn("w-fit font-medium gap-1")}>
            <span className="text-muted-foreground">Pays:</span>
            {job.pay_min || job.pay_max
              ? payRangeTextContent(decimalToNumber(job.pay_min), decimalToNumber(job.pay_max))
              : undefined}{" "}
            {job.pay_estimated && "(Est.)"}
          </Badge>
        )}
        <ButtonAnchor
          variant="outline"
          href={`https://www.google.com/maps/@${job.lat},${job.lon},13z`} // preview commute goes to google maps for time being
          target="_blank"
          onClick={() => analytics.track("Preview Commute Button Clicked", { job })}
        >
          🚲 Preview Commute
        </ButtonAnchor>
      </Flex>
      <hr />
      {/* thumbs up and thumbs down needs setup for backend */}
      <Flex align="center" gap="sm">
        <Button
          variant="outline"
          fullWidth
          className={cn("font-medium gap-1.5")}
          onClick={onDislikeButtonClick}
          disabled={dislikedJobs?.includes(job.id)}
        >
          <span className="mt-0.5">👎</span> Not for me
        </Button>
        <Button
          variant="outline"
          fullWidth
          className={cn("font-medium gap-1.5")}
          onClick={onLikeButtonClick}
          disabled={likedJobs?.includes(job.id)}
        >
          <span className="mt-0.5">👍</span> More like this
        </Button>
      </Flex>
    </>
  )
}
