import { useLocalStorageValue } from "@react-hookz/web"
import { useMap } from "@vis.gl/react-google-maps"
import { useCallback } from "react"
import { useAnalytics } from "./useAnalytics"
import { useQuery } from "@tanstack/react-query"
import { useMapLayer } from "./statsig"

export type LayerType = "transit" | "bike"

/**
 * Hook that can control what layers are shown on the map (i.e. transit/bike). The preferences for this are stored in
 * local storage so that they can be persisted across sessions.
 */
export function useMapLayers() {
  const analytics = useAnalytics()
  const map = useMap()
  const { defaultLayersEnabled } = useMapLayer()
  // Storing the enabled layers in local storage so they are persistent across page loads.
  const mapLayersLocalStorage = useLocalStorageValue<LayerType[]>("enabledMapLayers", {
    defaultValue: defaultLayersEnabled(),
    initializeWithValue: false,
  })
  // Because we are controlling this in a hook, where multiple instances can exist, and not a context provider, we must
  // store the layers in the QueryProvider as they must be stable in order to toggle on and off.
  const {
    data: [transitLayer, bikeLayer],
  } = useQuery({
    queryKey: ["map", "layers", !!map],
    initialData: [undefined, undefined],
    queryFn: () => {
      if (!map) {
        return [undefined, undefined]
      }

      const transitLayer = new google.maps.TransitLayer()
      const bikeLayer = new google.maps.BicyclingLayer()

      if (mapLayersLocalStorage.value?.includes("transit")) {
        transitLayer.setMap(map)
      }

      if (mapLayersLocalStorage.value?.includes("bike")) {
        bikeLayer.setMap(map)
      }

      return [transitLayer, bikeLayer] as const
    },
  })

  const set = useCallback(
    (layers: LayerType[]) => {
      // Track and save the values even if the map is not set. When the map is initialized, the layers will be set.
      mapLayersLocalStorage.set(layers)
      analytics.track("Map Layer Toggled", { enabled_layers: layers })

      if (!map) {
        return
      }

      transitLayer?.setMap(layers.includes("transit") ? map : null)
      bikeLayer?.setMap(layers.includes("bike") ? map : null)
    },
    [analytics, map, transitLayer, bikeLayer, mapLayersLocalStorage]
  )

  return { set, enabledLayers: mapLayersLocalStorage.value ?? [] }
}
