import * as React from "react"
import { Slot } from "@radix-ui/react-slot"
import { cva, type VariantProps } from "class-variance-authority"
import { cn } from "@/lib/frontend/shadcn"

// Define variants for the Text component using TailwindCSS classes
const textVariants = cva(
  "", // default text size
  {
    variants: {
      color: {
        primary: "text-primary",
        secondary: "text-secondary",
        accent: "text-accent",
        muted: "text-[#868e96]",
      },
      size: {
        xs: "text-xs",
        sm: "text-sm",
        md: "text-base",
        lg: "text-lg",
        xl: "text-xl",
        "2xl": "text-2xl",
        "3xl": "text-3xl",
        "4xl": "text-4xl",
        "5xl": "text-5xl",
        "6xl": "text-6xl",
        "7xl": "text-7xl",
        "8xl": "text-8xl",
        "9xl": "text-9xl",
      },
      weight: {
        light: "font-light", // 300
        normal: "font-normal", // 400
        medium: "font-medium", // 500
        semibold: "font-semibold", // 600
        bold: "font-bold", // 700
      },
      align: {
        left: "text-left",
        center: "text-center",
        right: "text-right",
      },
      transform: {
        uppercase: "uppercase",
        lowercase: "lowercase",
        capitalize: "capitalize",
      },
      lineClamp: {
        1: "line-clamp-1",
        2: "line-clamp-2",
        3: "line-clamp-3",
      },
      prose: {
        true: "prose",
      },
      unsafeHtml: {
        true: cn(
          // Hide any empty elements
          "[&_*:empty:not(br)]:hidden",
          // Make h1-h6 the same size and have the same spacing
          "[&_:is(:where(h1,h2,h3,h4,h5,h6))]:text-lg",
          "[&_:is(:where(h1,h2,h3,h4,h5,h6))]:my-4",
          // Lists next to paragraphs and vice versa should only have one margin
          "[&_p+ul]:mt-0 [&_ul+p]:mt-0 [&_p+ol]:mt-0 [&_ol+p]:mt-0",
          // Same margin rules apply to paragraphs next to lists and paragraphs next to paragraphs
          "[&_p+p]:mb-0",
          // First and last paragraphs in list items should not have margin
          "[&_li>p:first-child]:mt-0 [&_li>p:last-child]:mb-0"
        ),
      },
    },
    defaultVariants: {
      color: undefined,
      size: "md",
      weight: undefined,
      align: undefined,
      transform: undefined,
      lineClamp: undefined,
    },
  }
)

export type TextProps = {
  asChild?: boolean
} & VariantProps<typeof textVariants> &
  React.HTMLAttributes<React.ElementType<HTMLElement>>

export const Text = React.forwardRef(
  (
    { asChild, color, size, weight, align, transform, lineClamp, prose, unsafeHtml, className, ...props }: TextProps,
    ref
  ) => {
    const Component = asChild ? Slot : "p"
    const computed = textVariants({
      color,
      size,
      weight,
      align,
      transform,
      lineClamp,
      prose,
      unsafeHtml,
      className,
    })
    return (
      <Component
        className={computed}
        // @ts-expect-error Typing a generic ref is annoying
        ref={ref}
        {...props}
      />
    )
  }
)
Text.displayName = "Text"
