"use client"

import { EnrichedJob, EnrichedStore } from "@/types"
import { useAdvancedMarkerRef } from "@vis.gl/react-google-maps"
import { useEffect, useState } from "react"
import { useWorkmapsContext } from "@/lib/frontend/hooks"
import { useAnalytics } from "./useAnalytics"
import { formatStoreMarkerLabel } from "@/lib/frontend/util"
import { jobCategories } from "@/lib/shared/categories"
import { useMapLayer, useStoreFeedLayer } from "./statsig"

type UseMapPinProps = {
  selectedStore?: EnrichedStore | null
  stores: EnrichedStore[]
  onStoreClick?: (store: EnrichedStore, trackingParams?: Record<string, any>) => void
}

export const useMapPin = ({ selectedStore, stores, onStoreClick }: UseMapPinProps) => {
  // `markerRef` and `marker` are needed to establish the connection between the marker and infowindow
  const [clickedPinMarkerRef, clickedPinMarker] = useAdvancedMarkerRef()
  const [clickedPinId, setClickedPinId] = useState<string>()
  const [currentJobIndex, setCurrentJobIndex] = useState(0)
  const currentJob = selectedStore?.jobs && selectedStore?.jobs[currentJobIndex]
  const { state, dispatch } = useWorkmapsContext()
  const analytics = useAnalytics()

  const { mapPinModal } = useStoreFeedLayer()
  const { mapPinVariations, pinPerkHighlights, pinHighlights } = useMapLayer()

  const perkHighlights = pinPerkHighlights()
  const highlights = pinHighlights()
  const pinVariation = mapPinVariations()

  const getMapPinVariation = () => {
    if (perkHighlights || highlights) {
      return "logo"
    }

    if (pinVariation !== "default" && pinVariation) {
      return pinVariation as "default" | "logo" | "logo_pay" | "pay"
    }

    return "default"
  }

  const getPinVariation = getMapPinVariation()

  const handleClose = () => {
    setClickedPinId(undefined)
    dispatch({ storeId: undefined, job: undefined })
    analytics.track("Map Pin Close Button Clicked", { job: currentJob, jobIndex: currentJobIndex })
  }

  useEffect(() => {
    if (clickedPinId && clickedPinId !== selectedStore?.id) {
      setClickedPinId(undefined)
    }
  }, [clickedPinId, selectedStore?.id])

  // reset to first job when new store is selected
  useEffect(() => {
    if (selectedStore?.id !== clickedPinId && selectedStore?.jobs) {
      setCurrentJobIndex(0)
    }
  }, [selectedStore, clickedPinId])

  const handleNextClick = () => {
    if (selectedStore?.jobs && currentJobIndex < selectedStore.jobs.length - 1) {
      setCurrentJobIndex((index) => {
        const nextIndex = index + 1
        dispatch({ job: selectedStore.jobs[nextIndex].id })
        return nextIndex
      })
    }
    analytics.track("Map Pin Next Button Clicked", { job: currentJob, jobIndex: currentJobIndex })
  }

  const handlePreviousClick = () => {
    if (selectedStore?.jobs && currentJobIndex > 0) {
      setCurrentJobIndex((index) => {
        const prevIndex = index - 1
        dispatch({ job: selectedStore.jobs[prevIndex].id })
        return prevIndex
      })
    }
    analytics.track("Map Pin Previous Button Clicked", { job: currentJob, jobIndex: currentJobIndex })
  }

  const getHighlight = (highlightJob: Omit<EnrichedJob, "store"> | undefined) => {
    const selectedFilters = state.jobCategories || []

    if (perkHighlights) {
      // see if jobs have job categories and if they're selected filters
      const perkHighlight = highlightJob?.job_categories
        ? jobCategories.filter((jobCategory) => {
            const isInJobCategories = highlightJob.job_categories?.includes(jobCategory.value)
            return selectedFilters.length > 0
              ? selectedFilters.includes(jobCategory.value) && isInJobCategories
              : isInJobCategories
          })
        : []

      // use the first filter for the perk highlight
      if (perkHighlight.length > 0) {
        return perkHighlight[0].label
      }

      // if filter isn't active, use pay as the fallback
      if (selectedFilters.length === 0 && highlightJob?.pay_max) {
        return formatStoreMarkerLabel({ pay: highlightJob.pay_max, includeDecimal: false })
      }
    }

    // use job title for highlight
    if (highlights) {
      return highlightJob?.title || undefined
    }
  }

  const handleMarkerClick = (store: EnrichedStore, highlightedJob: Omit<EnrichedJob, "store"> | undefined) => {
    const trackingParams = {
      perkHighlighted: perkHighlights && !!highlightedJob,
      highlighted: highlights && !!highlightedJob,
      mapPinModal: mapPinModal(),
    }

    onStoreClick?.(store, trackingParams)

    if (mapPinModal()) {
      setClickedPinId(store.id)
      setCurrentJobIndex(0) // reset to first job when clicking a new pin

      if (store.jobs && store.jobs.length > 0) {
        dispatch({
          job: store.jobs[0].id,
          storeId: store.id,
        })
      }
    }
  }

  useEffect(() => {
    const handleJobCardClick = (event: CustomEvent<{ storeId: string; jobIndex: number; jobId: string }>) => {
      const store = stores.find((store) => store.id === event.detail.storeId)

      if (store) {
        setClickedPinId(store.id)
        setCurrentJobIndex(event.detail.jobIndex)
        dispatch({
          job: event.detail.jobId,
          storeId: store.id,
        })
      }
    }

    // @ts-expect-error Custom event listeners have bad types
    window.addEventListener("jobCardClicked", handleJobCardClick)

    // @ts-expect-error Custom event listeners have bad types
    return () => window.removeEventListener("jobCardClicked", handleJobCardClick)
  }, [stores, dispatch])

  return {
    clickedPinMarkerRef,
    clickedPinMarker,
    clickedPinId,
    currentJobIndex,
    currentJob,
    handleClose,
    handleNextClick,
    handlePreviousClick,
    getHighlight,
    handleMarkerClick,
    getPinVariation,
    experiments: {
      perkHighlights,
      highlights,
      pinVariation,
    },
  }
}
