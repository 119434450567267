import { useSession } from "@/components/SessionContext"
import type { UserJobPreferencesCommuteParams, UserJobPreferencesSearchJobsParams } from "@/dal/userJobPreferences"
import { api } from "@/lib/api"
import type { ApiResponse } from "@/types"
import type { UserJobPreferencesResponse } from "@/app/api/users/me/job-preferences/schemas"
import { useMutation, useQuery, useQueryClient } from "@tanstack/react-query"
import type { NegativeEmployerFeedback } from "@prisma/client"
import type { RecordNegativeEmployerFeedbackPayload } from "@/app/api/users/me/employer-preferences/route"
import { useAnalytics } from "./useAnalytics"

export function useUserJobPreferences() {
  const session = useSession()
  const queryClient = useQueryClient()
  const analytics = useAnalytics()

  const query = useQuery({
    queryKey: ["userJobPreferences"],
    // Wait for the initial set of stores to load so that the route can capture the page's query parameters in the job
    // search preferences.
    enabled: !!session,
    refetchOnMount: false,
    queryFn: async () => {
      const response = await api.get("/api/users/me/job-preferences")

      if (!response.ok) {
        throw response
      }

      const data = await response.json<ApiResponse<{ preferences: UserJobPreferencesResponse }>>()

      if (!data.ok) {
        throw new Error(data.error)
      }

      return data.preferences
    },
  })

  const updateSearchParamsMutation = useMutation({
    mutationKey: ["userJobPreferences", "updateSearchParamsMutation"],
    mutationFn: async (searchParams: Partial<UserJobPreferencesSearchJobsParams>) => {
      const response = await api.patch("/api/users/me/job-preferences", {
        json: { search_params: searchParams },
      })

      if (!response.ok) {
        throw response
      }

      const data = await response.json<ApiResponse<{ preferences: UserJobPreferencesResponse }>>()

      if (!data.ok) {
        throw new Error(data.error)
      }

      queryClient.setQueryData(["userJobPreferences"], data.preferences)

      return data.preferences
    },
  })

  const updateCommuteSettingsMutation = useMutation({
    mutationKey: ["userJobPreferences", "updateCommuteSettingsMutation"],
    mutationFn: async (commuteParams: Partial<UserJobPreferencesCommuteParams>) => {
      const response = await api.patch("/api/users/me/job-preferences", {
        json: { commute_settings: commuteParams },
      })

      if (!response.ok) {
        throw response
      }

      const data = await response.json<ApiResponse<{ preferences: UserJobPreferencesResponse }>>()

      if (!data.ok) {
        throw new Error(data.error)
      }

      queryClient.setQueryData(["userJobPreferences"], data.preferences)
      queryClient.invalidateQueries({ queryKey: ["user+session"] })

      return data.preferences
    },
  })

  const { data: negativeFeedback } = useQuery<Record<string, string>>({
    queryKey: ["userJobPreferences", "negativeEmployerFeedback"],
    initialData: {},
    queryFn: () => {
      return JSON.parse(localStorage.getItem("negativeEmployerFeedback") ?? "{}")
    },
  })

  const recordNegativeEmployerFeedback = useMutation({
    mutationKey: ["userJobPreferences", "recordNegativeEmployerFeedback"],
    mutationFn: async (payload: RecordNegativeEmployerFeedbackPayload) => {
      const response = await api.post("/api/users/me/employer-preferences", {
        json: payload,
      })

      if (!response.ok) {
        throw response
      }

      const data = await response.json<ApiResponse<{ result: NegativeEmployerFeedback }>>()

      if (!data.ok) {
        throw new Error(data.error)
      }

      localStorage.setItem(
        "negativeEmployerFeedback",
        JSON.stringify({ ...negativeFeedback, [payload.employer_id]: data.result.id })
      )

      queryClient.invalidateQueries({ queryKey: ["userJobPreferences", "negativeEmployerFeedback"] })

      analytics.track("Negative Employer Feedback Submitted", { reasons: payload.reasons, feedback: payload.feedback })

      return data.result
    },
  })

  return {
    preferences: query.data,
    loading: query.isLoading,
    error: query.error,
    updateJobSearchParams: updateSearchParamsMutation,
    negativeFeedback,
    recordNegativeEmployerFeedback,
    updateCommuteSettings: updateCommuteSettingsMutation,
  }
}
